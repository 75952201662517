<script>
// import PageHeader from "@/components/page-header";
import UserSercviceApi from "@/service/api/users";

export default {
  data() {
    return {
      userId: this.$route.params.id,
      userData: null,
    };
  },
  methods: {},
  mounted() {
    UserSercviceApi.getUserDetails(this.userId).then((response) => {
      this.userData = response.data[0];
    });
  },
  computed: {
    userType() {
      switch (this.userData.role) {
        case "S":
          return "System Admin";
        case "A":
          return "Admin";
        case "U":
          return "Normal User";
      }
      return this.userData.role;
    },
    verificationStatus() {
      switch (this.userData.verification) {
        case "1":
          return "Done";
        case "0":
          return "Pending";
      }
      return this.userData.verification;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-xl-4 col-lg-4">
      <div class="card">
        <div class="card-body" v-if="userData">
          <div class="text-center">
            <div>
              <img
                :src="userData.image"
                alt
                class="avatar-xl rounded-circle mt-2 mb-2"
              />
              <div>
                <i
                  class="mdi mdi-circle text-success align-middle mr-1"
                  v-if="userData.status === 'active'"
                ></i>
                <i
                  class="mdi mdi-circle text-danger align-middle mr-1"
                  v-else
                ></i>
                {{ userData.status }}
              </div>
            </div>
            <div class="media-body">
              <p class="text-muted">
                {{
                  userData.name +
                    " " +
                    (userData.lastName ? userData.lastName : "")
                }}
                <br />
                <b-badge variant="primary">{{ userType }}</b-badge>
              </p>
            </div>
          </div>
          <hr class="my-4" />
          <div class="user-details">
            <div class="row">
              <div class="col-4">
                Email
              </div>
              <div class="col-8">
                {{ userData.email }}
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                verification
              </div>
              <div class="col-8">
                <i
                  v-if="verificationStatus === 'Done'"
                  class="ri-checkbox-circle-fill text-success align-middle mr-1"
                ></i>
                <i
                  v-else
                  class="ri-close-circle-fill text-danger align-middle mr-1"
                ></i>
                {{ verificationStatus }}
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                Balance
              </div>
              <div class="col-8">
                {{ userData.balance }}
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                Apple id
              </div>
              <div class="col-8">
                <span v-if="userData.apple_id">
                  {{ userData.apple_id }}
                </span>
                <span v-else>
                  N/A
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                Size Pref
              </div>
              <div class="col-8">
                <span v-if="userData.sizePref">
                  {{ userData.sizePref }}
                </span>
                <span v-else>
                  N/A
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div
            id="accordion"
            class="custom-accordion categories-accordion mb-3"
          >
            <div class="categories-group-card">
              <a
                v-b-toggle.kids
                class="categories-group-list collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <i
                  class="ri-settings-2-fill font-size-16 align-middle mr-2"
                ></i>
                Settings
                <i class="mdi mdi-minus float-right accor-plus-icon"></i>
              </a>
              <b-collapse
                id="kids"
                data-parent="#accordion"
                accordion="my-accordion"
              >
                <div>
                  <ul class="list-unstyled categories-list mb-0">
                    <li>
                      <a
                        class="route-link-st"
                        @click="$router.push({ path: `edit` })"
                      >
                        <i class="mdi mdi-circle-medium mr-1"></i> Edit
                      </a>
                    </li>
                    <li>
                      <a
                        class="route-link-st"
                        @click="$router.push({ path: `disable` })"
                      >
                        <i class="mdi mdi-circle-medium mr-1"></i> Disable
                      </a>
                    </li>
                  </ul>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-8">
      <router-view :userData="userData" />
    </div>
  </div>
  <!-- end row -->
</template>

<style scoped>
.user-details > .row {
  margin-bottom: 15px;
}
.user-details .col-4 {
  font-weight: 900;
  font-size: 14px;
}
.route-link-st:hover {
  cursor: pointer;
  color: gray;
}
</style>
